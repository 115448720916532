<template>
  <aside
    ref="MainContent"
    class="fixed top-0 bottom-0 z-40 flex justify-end w-full left-full"
  >
    <div
      data-dialog-box
      class="h-full px-5 py-5 bg-zinc-900 text-white max-w-[90%] w-[500px] flex flex-col relative z-10"
    >
      <div class="mb-5">
        <div>
          <p
            class="inline-block text-4xl font-black text-transparent text-white uppercase"
          >
            ShopCart
          </p>
        </div>
        <div class="flex items-center justify-between">
          <p class="text-sm text-primary">購物車</p>
          <p class="text-sm work_sans">{{ shop_cart_count }} 項商品</p>
        </div>
      </div>
      <ol class="flex-1 w-full">
        <li
          v-for="(item, item_index) in shop_cart_data"
          :key="`shop_cart_item_${item_index}`"
          class="relative block w-full"
        >
          <ProductCard
            :shop_cart_item="item"
            :shop_cart_index="item_index"
            :is_member="is_member"
          />
        </li>
      </ol>

      <div class="pt-5 border-t border-zinc-600">
        <div v-if="shop_cart_count > 0">
          <p class="mb-5 text-sm text-zinc-500">運費與折扣將於結帳頁面計算</p>
          <button
            @click="
              $router.push('/checkout'),
                $store.commit('SetShopCartStatus', false)
            "
            class="flex items-center justify-center w-full px-10 py-4 text-sm font-bold text-center text-white transition-colors duration-500 bg-opacity-50 border border-black sm:text-base bg-primary md:hover:bg-opacity-100"
          >
            前往結帳
          </button>
        </div>
        <button
          v-else
          @click="
            $router.push('/scooters/all'),
              $store.commit('SetShopCartStatus', false)
          "
          class="flex items-center justify-center w-full px-10 py-4 text-sm font-bold text-center text-white transition-colors duration-500 bg-opacity-50 border border-black sm:text-base bg-primary md:hover:bg-opacity-100"
        >
          前往購物
        </button>
      </div>
    </div>

    <div
      data-dialog-background
      @click="$store.commit('SetShopCartStatus', false)"
      class="absolute top-0 left-0 z-0 w-full h-full bg-black bg-opacity-20 backdrop-blur-md"
    ></div>
  </aside>
</template>

<script>
import { getLocalStorage } from "@/common/cookie";
import { ShopCart } from "@/gsap/ShopCart";
import { GTM_OpenShopCart } from "@/common/gtm_methods";
import ProductCard from "@/components/shopcart/ProductCard.vue";
export default {
  name: "ShopCartDrawer",
  components: {
    ProductCard,
  },
  data() {
    return {
      dialog_gsap: null,
    };
  },
  computed: {
    shop_cart_open() {
      return this.$store.state.shop_cart_open;
    },
    shop_cart_data() {
      return this.$store.state.shopcart_module.shopcart;
    },
    // 是否登入會員
    is_member() {
      return getLocalStorage("account_token") ? true : false;
    },
    shop_cart_count() {
      let count = 0;
      this.shop_cart_data.forEach((item) => {
        count += parseInt(item.amount);
      });
      return count;
    },
  },
  watch: {
    shop_cart_open() {
      if (this.shop_cart_open) {
        this.dialog_gsap.open();
        GTM_OpenShopCart(this.shop_cart_data);
      } else {
        this.dialog_gsap.close();
      }
    },
  },
  mounted() {
    this.dialog_gsap = new ShopCart(this.$refs.MainContent);
    this.dialog_gsap.reset();
  },
};
</script>
