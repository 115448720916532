<template>
  <div
    id="MainLoading"
    ref="MainContent"
    class="fixed top-0 left-full w-full bottom-0 z-[51] bg-black bg-opacity-50 flex items-center justify-center"
  >
    <div>
      <svg
        ref="LoadingLogo"
        class="w-80"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 530.55 115.4"
      >
        <polygon
          class="cls-1"
          points="104.64 .5 104.64 .5 86.79 .5 1.28 79 19.84 79 86.93 16.89 72.41 62.44 45.9 77.5 106.39 77.5 131.05 .5 104.64 .5"
        />
        <polygon
          class="cls-1"
          points="195.49 39.74 171.26 55.31 230.37 55.31 230.37 1.25 139.21 1.25 115.04 78.25 143.95 78.25 162.11 20.4 201.55 20.4 195.49 39.74"
        />
        <path
          class="cls-1"
          d="m266.14,77.5h37.28c7.19-6.5,14.38-12.99,21.25-19.25h-58.53v-9.64h69.28c3.02-2.74,6.01-5.39,8.87-8.01l-7.8-11.24h-70.34v-9.64h63.58l-13.36-19.21h-50.22s-26.89-.01-26.89-.01v77h26.89Z"
        />
        <polygon
          class="cls-1"
          points="317 77.52 353.9 77.52 365.9 64.9 351.56 44.25 317 77.52"
        />
        <polygon
          class="cls-1"
          points="411.63 64.01 395.52 40.71 395.49 40.73 379.82 18.2 378.15 15.83 376.51 13.46 367.48 .5 328.58 .5 352.61 34.99 354.29 37.36 355.92 39.77 369.42 59.13 369.43 59.13 382.06 77.5 397.62 77.5 397.6 77.52 434.5 77.52 446.51 64.9 432.16 44.25 411.63 64.01"
        />
        <path
          class="cls-1"
          d="m476.09,40.73l-15.66-22.53-1.68-2.37-1.63-2.37-8.93-12.81.2-.15h-58.18c5.94,1.04,5.59,3.76,2.56,6.17-.65.53-3.72,3.27-8.62,7.6l15.53,21.17,22.57-16.18,10.96,15.74,1.68,2.37,1.63,2.41,13.5,19.36h0s12.64,18.37,12.64,18.37h38.9l-25.45-36.79-.03.02Z"
        />
        <path
          class="cls-1"
          d="m473.38,6.67c-.65.53-3.72,3.27-8.62,7.6l15.53,21.17L528.99.5h-58.18c5.94,1.04,5.59,3.76,2.56,6.17Z"
        />
        <path
          class="cls-1"
          d="m201.06,103.68c1.12-1.45,1.68-3.08,1.68-4.88,0-3.19-1.39-5.65-4.18-7.38-2.48-1.51-5.72-2.27-9.72-2.27h-16.14l-5.18,6.6h22.06c3.33,0,5,1,5,3.01,0,1.03-.56,1.81-1.67,2.33-.88.41-1.99.61-3.33.61h-22.06v13.02h7.95v-6.42h10.92l7.73,6.42h11.53l-9.26-7.7c1.99-.78,3.54-1.9,4.67-3.35Z"
        />
        <path
          class="cls-1"
          d="m230.83,90.01c-.99-.62-1.98-.92-2.94-.92s-1.93.3-2.95.92c-.92.57-1.64,1.23-2.16,1.98l-16.39,22.73h9.82l11.53-15.82,6.63,9.22h-10.67l-4.79,6.6h30.04l-15.99-22.73c-.54-.78-1.25-1.44-2.13-1.98Z"
        />
        <path
          class="cls-1"
          d="m259.2,97.51c1.42-1.18,3.21-1.76,5.38-1.76h12.82l5.1-6.6h-17.94c-4.51,0-8.21,1.16-11.1,3.47-2.88,2.32-4.33,5.35-4.33,9.08s1.46,6.84,4.38,9.31c2.92,2.47,6.6,3.71,11.04,3.71h12.84l5.11-6.6h-17.92c-2.17,0-3.97-.57-5.38-1.71-1.42-1.14-2.12-2.61-2.12-4.41s.71-3.32,2.12-4.5Z"
        />
        <rect class="cls-1" x="284.66" y="89.16" width="7.94" height="25.57" />
        <path
          class="cls-1"
          d="m318.99,103.23l-14.97-12.55c-1.23-1.02-2.57-1.53-4.01-1.53-1.11,0-1.97.31-2.57.92-.6.62-.91,1.4-.91,2.35v22.3h7.95v-14.04l14.97,12.52c1.37,1.14,2.81,1.7,4.32,1.7,2.11,0,3.16-1.16,3.16-3.48v-22.27h-7.94v14.08Z"
        />
        <path
          class="cls-1"
          d="m339.39,97.51c1.43-1.18,3.23-1.76,5.41-1.76h12.83l5.11-6.6h-17.94c-4.52,0-8.22,1.16-11.1,3.47-2.88,2.32-4.32,5.35-4.32,9.08s1.46,6.84,4.38,9.31c2.92,2.47,6.6,3.71,11.05,3.71h17.16v-15.29h-23.09l5.21,6.31h9.93v2.38h-9.22c-2.2,0-4.01-.56-5.43-1.69s-2.13-2.6-2.13-4.43.72-3.32,2.14-4.5Z"
        />
      </svg>
    </div>
  </div>
</template>

<script>
import { loading_animation } from "@/gsap/Loading";
export default {
  name: "MainLoading",
  computed: {
    main_loading() {
      return this.$store.state.loading;
    },
    // first_loading() {
    //   return this.$store.state.first_loading;
    // },
  },
  watch: {
    main_loading(new_val, old_val) {
      if (new_val != 0 && !this.first_loading) {
        this.loading_animation.open();
      } else if (old_val != 0 && new_val == 0) {
        this.loading_animation.close();
      }
    },
    // first_loading() {
    //   if (this.main_loading != 0 && !this.first_loading) {
    //     this.loading_animation.open();
    //   }
    // },
  },
  data() {
    return {
      loading_animation: null,
    };
  },
  mounted() {
    this.loading_animation = new loading_animation(this.$refs.MainContent);
    // this.loading_animation.open();
  },
};
</script>

<style>
.cls-1 {
  fill: none;
  stroke: #fff;
  stroke-miterlimit: 10;
}
</style>
