import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/dealers",
    name: "dealers",
    component: () => import("../views/DealerView.vue"),
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
  },
  {
    path: "/news",
    name: "news_list",
    component: () => import("../views/NewsListView.vue"),
  },
  {
    path: "/news/:id",
    name: "news_page",
    component: () => import("../views/NewsView.vue"),
  },
  {
    path: "/video/list",
    name: "video_list",
    component: () => import("../views/VideoListView.vue"),
  },
  {
    path: "/video/:id",
    name: "video_page",
    component: () => import("../views/VideoView.vue"),
  },
  {
    path: "/scooters/:id",
    name: "scooters",
    component: () => import("../views/ScooterListView.vue"),
  },
  {
    path: "/collections",
    name: "collections",
    component: () => import("../views/ProductListView.vue"),
  },
  {
    path: "/product/:id",
    name: "product_page",
    component: () => import("../views/ProductView.vue"),
  },
  {
    path: "/support",
    name: "contact_page",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () => import("../views/CheckoutView.vue"),
  },
  {
    path: "/order_create/:id",
    name: "trade_finish_page",
    component: () => import("../views/TradeFinishView.vue"),
  },
  {
    path: "/terms/:id",
    name: "terms_page",
    component: () => import("../views/TermsView.vue"),
  },
  {
    path: "/order/search",
    name: "order_search_page",
    component: () => import("../views/OrderSearchView.vue"),
  },
  {
    path: "/order/result",
    name: "order_search_result_page",
    component: () => import("../views/OrderSearchResultView.vue"),
  },
  {
    path: "/account/login",
    name: "login_page",
    component: () => import("../views/LoginView.vue"),
  },
  {
    path: "/account/forget_pwd",
    name: "forget_password_page",
    component: () => import("../views/ForgetPasswordView.vue"),
  },
  {
    path: "/account/signup",
    name: "signup_page",
    component: () => import("../views/SignupView.vue"),
  },
  {
    path: "/account/",
    name: "會員中心",
    component: () => import("../views/MemberCenterView.vue"),
    meta: { requiresAuth: true },
    children: [
      {
        path: "account_edit",
        name: "編輯會員資料",
        component: () => import("../views/MemberCenter/InfoEditView.vue"),
      },
      {
        path: "modify_password",
        name: "更改密碼",
        component: () => import("../views/MemberCenter/PasswordEditView.vue"),
      },
      {
        path: "orders",
        name: "訂單查詢",
        component: () => import("../views/MemberCenter/OrderListView.vue"),
      },
      {
        path: "order/:id",
        name: "訂單詳細",
        component: () => import("../views/MemberCenter/OrderPageView.vue"),
      },
      {
        path: "addresses",
        name: "收件地址管理",
        component: () => import("../views/MemberCenter/AddressListView.vue"),
      },
    ],
  },
  {
    path: "/event/:id",
    name: "event_page",
    component: () => import("../views/EventPageView.vue"),
  },
  {
    path: "/error_page",
    name: "error_page",
    component: () => import("../views/ErrorView.vue"),
  },
  {
    path: "*",
    redirect: "/error_page",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  // 換頁滾動至最上面
  scrollBehavior: function (to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    if (to.hash) {
      return { el: to.hash, behavior: "smooth" };
    } else {
      window.scrollTo(0, 0);
    }
  },
});

function hasQueryParams(route) {
  return !!Object.keys(route.query).length;
}

router.beforeEach((to, from, next) => {
  if (hasQueryParams(from) && from.query.order_memo) {
    if (to.query && to.query.order_memo) {
      next();
    } else {
      let new_query = null;
      new_query = to.query ? to.query : from.query;
      if (!to.order_memo) {
        new_query.order_memo = from.query.order_memo;
      }
      next({ name: to.name, query: new_query, params: to.params });
    }
  } else {
    next();
  }
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

export default router;
