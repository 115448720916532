import { gsap } from "@/gsap/GsapLoader";

export class MainProductSection {
  constructor(el) {
    this.el = el;
    this.image = el.querySelector("[data-section-image]");
    this.content = el.querySelector("[data-section-content]");
    this.bg = el.querySelector("[data-section-bg]");
    this.frame = el.querySelectorAll("[data-section-frame]");

    this.timeline = null;
    this.scrub_timeline = null;
  }

  reset() {
    gsap.set(this.el, { opacity: 1 });
    gsap.set(this.image, { opacity: 0, blur: 10 });
    gsap.set(this.content, { opacity: 0, blur: 10 });
    gsap.set(this.bg, { y: "-50%", opacity: 0, blur: 10 });
    gsap.set(this.frame[0], { x: "-10px", y: "-10px", opacity: 0 });
    gsap.set(this.frame[1], { x: "10px", y: "-10px", opacity: 0 });
    gsap.set(this.frame[2], { x: "-10px", y: "10px", opacity: 0 });
    gsap.set(this.frame[3], { x: "10px", y: "10px", opacity: 0 });
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = null;
    this.scrub_timeline != null ? this.scrub_timeline.kill() : "";
    this.scrub_timeline = null;
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: "top 70%",
        toggleActions: "restart play play reverse",
      },
    });

    this.timeline.to(
      this.image,
      {
        opacity: 1,
        blur: 0,
      },
      "first"
    );
    this.timeline.to(
      this.frame,
      {
        x: "0px",
        y: "0px",
        opacity: 1,
      },
      "first"
    );
    this.timeline.to(
      this.content,
      {
        opacity: 1,
        blur: 0,
      },
      "second"
    );
    this.timeline.to(
      this.bg,
      {
        opacity: 1,
        blur: 0,
      },
      "first"
    );

    this.scrub_timeline != null ? this.scrub_timeline.kill() : "";
    this.scrub_timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: "top 70%",
        scrub: 1,
      },
    });
    this.scrub_timeline.to(this.bg, { y: "50%" });
  }
}
