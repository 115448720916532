var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{ref:"MainContent",staticClass:"overflow-hidden md:py-10 main_container",attrs:{"id":"HomeMainProduct"}},[_c('div',{staticClass:"relative flex flex-col-reverse flex-wrap items-center justify-between py-10 -mx-10 md:flex-row md:px-5"},[_c('div',{staticClass:"relative z-10 flex items-center w-full p-10 sm:p-20 md:p-10 md:w-1/2"},[_c('div',{staticClass:"relative z-10",attrs:{"data-section-content":""}},[_c('p',{staticClass:"mb-0 font-bold sm:text-lg text-primary"},[_vm._v("PRODUCT")]),_c('div',{staticClass:"relative mb-5"},[_c('h3',{staticClass:"text-3xl font-black text-white sm:text-5xl",domProps:{"innerHTML":_vm._s(_vm.$GetColumn('home_promote_product_2_title'))}})]),_c('div',{staticClass:"mb-10 text-sm font-medium text-zinc-400",domProps:{"innerHTML":_vm._s(
            _vm.$GetColumn('home_promote_product_2_content').replace(
              /\r?\n/g,
              '<br />'
            )
          )}}),_c('div',[_c('a',{staticClass:"inline-flex items-center py-2 pl-8 pr-6 text-sm font-bold text-white transition-colors duration-500 bg-opacity-50 border border-black md:text-base bg-primary md:hover:bg-opacity-100",attrs:{"href":_vm.$GetColumn('home_promote_product_2_link')}},[_vm._v("查看商品 "),_c('i',{staticClass:"block ml-3 transform -rotate-45 icon-arrow-right"})])])]),_c('div',{staticClass:"absolute z-0 transform -translate-y-1/2 opacity-10 md:opacity-20 right-10 top-1/2"},[_c('LazyLoadingImage',{staticClass:"block w-52",attrs:{"data-section-bg":"","src":"/img/logo_icon.svg"}})],1),_vm._m(0)]),_c('div',{staticClass:"relative z-10 w-full px-10 md:w-1/2"},[_c('div',{staticClass:"w-full"},[_c('LazyLoadingImage',{staticClass:"block object-cover w-full",attrs:{"data-section-image":"","src":_vm.$ImageUrl(_vm.$GetColumn('home_promote_product_2_image'))}})],1)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absolute z-0 hidden sm:block md:top-0 md:bottom-0 md:left-0 md:right-0 top-10 left-10 right-10 bottom-10"},[_c('div',{staticClass:"absolute top-0 left-0",attrs:{"data-section-frame":""}},[_c('i',{staticClass:"block w-5 h-[2px] md:h-[3px] bg-zinc-600"}),_c('i',{staticClass:"block md:w-[3px] w-[2px] h-5 bg-zinc-600"})]),_c('div',{staticClass:"absolute top-0 right-0",attrs:{"data-section-frame":""}},[_c('i',{staticClass:"block w-5 h-[2px] md:h-[3px] bg-zinc-600"}),_c('i',{staticClass:"block md:w-[3px] w-[2px] h-5 ml-auto bg-zinc-600"})]),_c('div',{staticClass:"absolute bottom-0 left-0",attrs:{"data-section-frame":""}},[_c('i',{staticClass:"block md:w-[3px] w-[2px] h-5 bg-zinc-600"}),_c('i',{staticClass:"block w-5 h-[2px] md:h-[3px] bg-zinc-600"})]),_c('div',{staticClass:"absolute bottom-0 right-0",attrs:{"data-section-frame":""}},[_c('i',{staticClass:"block md:w-[3px] w-[2px] h-5 ml-auto bg-zinc-600"}),_c('i',{staticClass:"block w-5 h-[2px] md:h-[3px] bg-zinc-600"})])])
}]

export { render, staticRenderFns }