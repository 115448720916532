<template>
  <div
    id="HeaderAnnouncement"
    ref="AnnouncementMarquee"
    v-if="announcement_list.length > 0"
    class="relative z-20 flex w-full py-1 overflow-hidden text-sm font-light text-center items-stretch-stretch bg-primary"
  >
    <p
      v-for="(item, item_index) in announcement_list"
      :key="`announcement_${item_index}`"
      :class="announcement_list.length == 1 ? 'top-0' : 'top-full'"
      class="relative flex items-center justify-center flex-shrink-0 w-full px-2 overflow-hidden"
    >
      <span v-if="item.link == ''" class="text-white">
        {{ item.content }}
      </span>
      <a
        v-else
        :href="item.link"
        target="_blank"
        :key="`announcement_${item_index}`"
        class="flex items-center justify-center w-full h-full overflow-hidden text-white"
      >
        {{ item.content }}
      </a>
    </p>
  </div>
</template>

<script>
import { AnnouncementMarquee } from "@/gsap/AnnouncementMarquee";
export default {
  name: "HeaderAnnouncement",
  data() {
    return {
      announcement_gsap: null,
    };
  },
  computed: {
    announcement_list() {
      return this.$store.getters.announcement_list;
    },
  },
  mounted() {
    if (this.announcement_list.length > 1) {
      this.announcement_gsap = new AnnouncementMarquee(
        this.$refs.AnnouncementMarquee
      );
      this.announcement_gsap.setup();
    }
  },
};
</script>
