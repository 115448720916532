<template>
  <section class="relative z-10 w-full py-20 bg-[#111]">
    <div class="main_container">
      <p class="mb-0 text-lg font-bold text-primary">CONTACT</p>
      <div class="relative mb-5">
        <h3
          class="text-3xl font-black text-white sm:text-5xl"
          v-html="$GetColumn('contact_footer_title')"
        ></h3>
      </div>
      <div
        class="mb-10 text-sm font-medium text-zinc-400"
        v-html="
          $GetColumn('contact_footer_content').replace(/\r?\n/g, '<br />')
        "
      ></div>
      <div class="">
        <a
          :href="$GetColumn('contact_footer_link')"
          target="_blank"
          class="inline-flex items-center py-2 pl-8 pr-6 text-sm font-bold text-white transition-colors duration-500 bg-opacity-50 border border-black sm:text-base bg-primary md:hover:bg-opacity-100"
          >聯絡我們
          <i class="block ml-3 transform -rotate-45 icon-arrow-right"></i>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "ContactFooter",
  data() {
    return {};
  },
};
</script>
