<template>
  <div
    ref="MainContent"
    class="absolute right-0 flex items-stretch top-full drop_down_box"
  >
    <ol
      class="flex-shrink-0 bg-black bg-opacity-90"
      v-for="(category, categoryIndex) in menu_category_data"
      :key="`drop_down_${categoryIndex}`"
    >
      <li
        v-for="(item, item_index) in category"
        :key="`drop_down_menu_${categoryIndex}_${item_index}`"
      >
        <router-link
          :to="`/scooters/${item.MenuCategoryID}`"
          @click.native="Close()"
          class="block px-5 py-3 text-white transition-all duration-300 md:hover:text-primary drop_down_item"
        >
          {{ item.Title }}
        </router-link>
      </li>
    </ol>
  </div>
</template>

<style>
.drop_down_box {
  pointer-events: none;
  opacity: 0;
  transform: translateY(-10px);
  transition: all 0.3s;
}
.drop_down_box.active {
  pointer-events: auto;
  opacity: 1;
  transform: translateY(0px);
}
</style>

<script>
export default {
  name: "DropDown",
  methods: {
    Close() {
      this.$refs.MainContent.classList.remove("active");
    },
  },
  computed: {
    menu_category_data() {
      let menu_list = [];
      if (this.$store.state.menu_category_data.length < 5) {
        return [this.$store.state.menu_category_data];
      }
      let tmp_list = [];
      this.$store.state.menu_category_data.forEach((item, itemIndex) => {
        if (itemIndex != 0 && itemIndex % 5 == 0) {
          menu_list.push(tmp_list);
          tmp_list = [];
        }
        tmp_list.push(item);
      });
      if (tmp_list.length > 0) {
        menu_list.push(tmp_list);
      }

      return menu_list;
    },
  },
};
</script>
