import { gsap } from "@/gsap/GsapLoader";

export class AboutSection {
  constructor(el) {
    this.el = el;
    this.cards = el.querySelectorAll("[data-section-card]");
    this.title = el.querySelectorAll("[data-section-title]");
    this.sub_title = el.querySelectorAll("[data-section-sub-title]");

    this.timeline = null;
  }

  reset() {
    gsap.set(this.el, { opacity: 1 });
    gsap.set(this.cards, { opacity: 0, y: "30px" });
    gsap.set(this.title, { opacity: 0, blur: 10 });
    gsap.set(this.sub_title, { x: "30px", opacity: 0 });
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = null;
  }

  setup() {
    this.timeline != null ? this.timeline.kill() : "";
    this.timeline = gsap.timeline({
      scrollTrigger: {
        trigger: this.el,
        start: "top 70%",
        toggleActions: "restart play play reverse",
      },
    });

    this.timeline.to(
      this.sub_title,
      {
        x: "0px",
        opacity: 1,
      },
      "first"
    );
    this.timeline.to(
      this.title,
      {
        blur: 0,
        opacity: 1,
      },
      "first"
    );
    this.timeline.to(
      this.cards,
      {
        y: "0px",
        opacity: 1,
        stagger: 0.1,
      },
      "first"
    );
  }
}
